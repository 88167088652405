import React, { useState } from 'react';
//import { Form, Card, Button } from 'react-bootstrap';
import './style.scss'
import Urlimg from '../../../assets/images/singin.svg'
import imageVVO from './../../../assets/images/logo_vvo.png'


const Login = (props) => {

    return (
        <section id="account" className="container">
            
            <div className="row m-0 k-justify-content-between">
    
    
                <div className="col-sm-12 col-md-12 content-form">
                    <div>
                        <div className="header text-center mb-4">
                            <img className="img-vvo-logo" src={imageVVO}/>
                            <h3>Bienvenidos</h3>
                            <p className="mb-5">  Ingreso al Sistema</p>
                        </div>
                        <form autoComplete="off">
                            <div className="form-group">
                                <input className="form-control" type="email" value={props.data.email} onChange={props.onHandleEmail} name="email" placeholder="Escribe su correo" />
                            </div>
                            <div className="form-group">
                                <input className="form-control" type="password" value={props.data.password} onChange={props.onHandlePassword} name="password" placeholder="Escribe su contraseña" />
                            </div>
                            <div className="form-group mb-0">                                
                                <a onClick={props.onSubmit} className="button btn-vvo btn-block round"> ingresar </a>
                            </div>
                            {/*<div className="form-group text-center">
                                <a href="#" className="text-secondary">¿olvidaste tu contreseña?</a>                                
                            </div>
                            <div className="form-group mt-5 pt-2 text-center">
                                <p className="m-0">¿Aún no tienes una cuenta? </p>                                
                                <a href="#" className="text-secondary"> CREAR CUENTA </a>
                            </div>*/}
                        </form>
                    </div>
                </div>
            </div>
    
        </section>
    )

}

export default Login;